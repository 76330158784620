import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ShareInfoService {

  private mainTexts = new BehaviorSubject(true);
  public mainTexts$ = this.mainTexts.asObservable();

  setPersonInformation = new BehaviorSubject(null);
  getPersonInformation$ = this.setPersonInformation.asObservable();

  private setDisabledSectionPersonInfo = new BehaviorSubject<number>(0);
  getDisabledSectionPersonInformation$ = this.setDisabledSectionPersonInfo.asObservable();

  setAddress = new BehaviorSubject(null);
  getAddress$ = this.setAddress.asObservable();

  setRequestId = new BehaviorSubject(null);
  getRequestId$ = this.setRequestId.asObservable();

  setRequestForDocumentForm = new BehaviorSubject(null);
  getRequestForDocumentForm$ = this.setRequestForDocumentForm.asObservable();

  setRequestInformation = new BehaviorSubject(null);
  getRequestInformation$ = this.setRequestInformation.asObservable();

  setRequestAuthorization = new BehaviorSubject(null);
  getRequestAuthorization$ = this.setRequestAuthorization.asObservable();

  private setRequestBasicData = new BehaviorSubject(null);
  getRequestBasicData$ = this.setRequestBasicData.asObservable();

  setResponseOffice = new BehaviorSubject(null);
  getResponseOffice$ = this.setResponseOffice.asObservable();

  disabledSectionPersonInfo(_section: number) {
    this.setDisabledSectionPersonInfo.next(_section);
  }

  shareRequestBasicInformation(request: any) {
    this.setRequestBasicData.next(request);
  }
}
