import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { AccesibilityComponent } from "./components/accesibility/accesibility.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { SigninComponent } from "./components/signin/signin.component";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../material.module";
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SelectRoleComponent } from './components/select-role/select-role.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NgxLoadingModule } from "ngx-loading";



@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    AccesibilityComponent,
    BreadcrumbComponent,
    SigninComponent,
    NotFoundComponent,
    SelectRoleComponent,
    LoadingComponent
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    AccesibilityComponent,
    BreadcrumbComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxLoadingModule
  ]
})
export class CoreModule { }
