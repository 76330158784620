export enum Rol {
  CitizenOrApplicant = 'solicitante',
  Validator = 'validador',
  Coordinator = 'coordinador',
  Specialist = 'especialista',
  VicePrincipal = 'subdirector',
  Director = 'director',
  Logistic = 'logistico',
  Revisor = 'revisor'
}

export enum RoleId {
  solicitante = 1,
  validador = 2,
  coordinador = 3,
  especialista = 4,
  subdirector = 5,
  logistico = 6
}
