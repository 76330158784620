import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Rol, RoutesApp } from "@enums";
import { AuthService } from "@services";

declare var utils: any;

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: [ './not-found.component.scss' ]
})
export class NotFoundComponent implements OnInit {

  currentUser: any;
  constructor(private _router: Router,
              private _authService: AuthService) {
  }

  ngOnInit() {
    utils.tooltip();
    this.currentUser = this._authService.currentUserValue;
    console.log(this.currentUser);
  }

  returnHome() {
    if (!this.currentUser) {
      this._router.navigate([ `/${ RoutesApp.SignIn }` ])
      return;
    }
    this.redirectByRole(this.currentUser.rol);
  }

  redirectByRole(role: string) {
    switch (role) {
      case Rol.CitizenOrApplicant:
        this._router.navigate([ RoutesApp.MainPage, RoutesApp.MainPageCitizen ]);
        break;
      case Rol.Logistic:
        this._router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.HomePageLogistic }`);
        break;
      case Rol.Validator:
        this._router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardValidator }`);
        break;
      case Rol.Coordinator:
        this._router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardCoordinator }`);
        break;
      case Rol.Director:
        this._router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardDirector }`);
        break;
      default:
        this._router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }`);
        break;
    }
  }

}
