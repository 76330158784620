export enum RoutesApp {
  SignIn = 'sign-in',
  MainPage = 'opa35',
  Dashboard = 'inbox',
  NotFound404 = 'not-found',

  // Citizen
  MainPageCitizen = 'request',
  ActionsRequestOpa35 = 'actions',
  AddRequestOpa35 = 'add-request',
  CorrectRequestOpa35 = 'correct-request/:request_id',
  AddRequestOpa35Logistic = 'add-request-logistic',
  DashboardCitizen = 'dashboard-citizen',
  ValidationRequestProcedureOpa35 = 'validation/:request_id',

  // Logistic
  HomePageLogistic = 'home-logistic',
  LogisticCorrectRequestOpa35 = 'logistic-correct-request/:request_id',

  // VALIDATORS
  DashboardValidator = 'dashboard-validator',
  MainValidators = 'validators',
  DashboardValidators = 'dashboard-validators',
  DashboardCoordinator = 'dashboard-coordinator',

  //Director
  DashboardDirector = 'dashboard-director',
  DashboardRevisor = 'dashboard-revisor',

  //Reports
  DashboardReports = 'dashboard-reports'
}
