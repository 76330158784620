import { DocsRequirement } from "@enums";

export const OPA_35 = {
  T_ID: 35,
  T_NAME: 'Servicio Digital Certificado de Discapacidad',
  T_DESCRIPTION: `Este trámite permite la digitalización y puesta en WEB de las solicitudes de los residentes en la ciudad de Bogotá, interesados en obtener la
  valoración multidisciplinaria para la certificación de discapacidad y posterior inclusión en el registro de localización y Caracterización de Personas con
  Discapacidad (RLCPD), que ingresan a la Dirección de Provisión de Servicios de Salud.
  `,
  FIRST_TIME: '2e8697c9-9e1a-4b92-a51e-8ffa9d2383ed',
  SECOND_OPINION: '67F18E0A-B3B3-408F-BAE0-56D1E7E92DED',
  OTRO_DISPO_MOVI: '09b5a984-6d00-4060-9800-fe9411d47fe0',
  OTRO_DISPO_COM: '09d19678-3898-4410-ba68-cd7b7b2897be',
  CANAL_VIRTUAL: '62F99457-D184-4DBD-B7B1-F231CB66E670',
  CANAL_PRESENCIAL: '6C977E62-0A98-4294-9E4C-09140CDBDDA1',
  CITIZEN: {
    B2C_USER: 'B2cUser',
    LOCAL_USER: 'localUser',
  }
};

export const AddressConstants = {
  ViaPrincipal: [
    { key: 'AK', value: 'AK - Avenida Carrera' },
    { key: 'AC', value: 'AC - Avenida Calle' },
    { key: 'CL', value: 'CL - Calle' },
    { key: 'DG', value: 'DG - Diagonal' },
    { key: 'KR', value: 'KR - Carrera' },
    { key: 'TV', value: 'TV - Transversal' },
  ],
  Letters: [ "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z" ],
}

export const StatesOpa35 = [
  {
    "IdConstante": "AE4C301C-39BC-44F0-A524-03D4116DDE7C",
    "Nombre": "Subsanado"
  },
  {
    "IdConstante": "8877A285-0C57-49C2-9B57-23441C2F238C",
    "Nombre": "Aprobado Validador"
  },
  {
    "IdConstante": "98C1E2AF-9360-4158-9268-38A778C195E6",
    "Nombre": "Sin Competencia (Régimen especifico/no reside en Bogotá)"
  },
  {
    "IdConstante": "3F74A183-0DE0-4D8D-8F7A-39B1F42AD563",
    "Nombre": "Por Validar"
  },
  {
    "IdConstante": "8BD73EC2-99AE-400D-A73B-3FEF8FEAFEAE",
    "Nombre": "Aprobado Revisor"
  },
  {
    "IdConstante": "5B4E1902-D66B-4348-87E1-4C352B7A6420",
    "Nombre": "Por Asignar"
  },
  {
    "IdConstante": "9569968E-B873-4183-A3DB-4F862D18D592",
    "Nombre": "Aprobado Coordinador"
  },
  {
    "IdConstante": "DCB1A689-C65E-4FD5-984E-770FEF8D761F",
    "Nombre": "Estados Tramites"
  },
  {
    "IdConstante": "0D7F95A7-25D1-4C62-98C5-85D837E6DD46",
    "Nombre": "Solicitar Más Información"
  },
  {
    "IdConstante": "04D09336-1DBD-4F0B-BED5-8DF5618FBAC4",
    "Nombre": "Devuelto Director"
  },
  {
    "IdConstante": "5838366F-4DFB-41AF-A324-95CC7A954EAA",
    "Nombre": "Devuelto Coordinador"
  },
  {
    "IdConstante": "7328A1EA-465C-41DB-A7BE-961086CA7D43",
    "Nombre": "Aprobado y Firmado"
  },
  {
    "IdConstante": "39D5A153-B551-4EF5-A8EF-E10AA8784CF6",
    "Nombre": "Devuelto Revisor"
  }
]

export const Localities = {
  1: 'Usaquén',
  2: 'Chapinero',
  3: 'Santa Fe',
  4: 'San Cristóbal',
  5: 'Usme',
  6: 'Tunjuelito',
  7: 'Bosa',
  8: 'Kennedy',
  9: 'Fontibón',
  10: 'Engativá',
  11: 'Suba',
  12: 'Barrios Unidos',
  13: 'Teusaquillo',
  14: 'Los Mártires',
  15: 'Antonio Nariño',
  16: 'Puente Aranda',
  17: 'La Candelaria',
  18: 'Rafael Uribe Uribe',
  19: 'Ciudad Bolivar',
  20: 'Sumapaz',
  21: 'Fuera de Bogotá',
  '': 'Sin Dato',
  null: 'Sin Dato',
  0: 'Sin Dato'
};

export const DocTypesShared = {
  1: 'Cédula de ciudadanía',
  2: 'Cédula de extranjería',
  3: 'Tarjeta de identidad',
  4: 'Permiso especial de permanencia',
  5: 'NIT',
  6: 'Pasaporte',
  7: 'Número de Protocolo Medicina Legal',
  8: 'Tarjeta de Extranjería',
  9: 'Registro Civil',
  10: 'Número Único de Identificación Personal',
  11: 'Sin Identificación',
  12: 'Otro Documento',
  13: 'Permiso Protección Temporal',
  null: 'Sin Dato',
  0: 'Sin Dato'
};

