import { Component, OnDestroy } from '@angular/core';
import { catchError, of, Subject, takeUntil } from "rxjs";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter, map } from "rxjs/operators";
import { InteractionStatus } from "@azure/msal-browser";
import { Rol, RoutesApp } from "@enums";
import { SSOAuthResponse } from "@models";
import { AuthService } from "@services";
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'Secretaría de Salud Bogotá';
  json;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  currentUser: SSOAuthResponse;

  constructor(// private nomenclatorService: NomenclatorService,
    // private store: Store<GlobalState>,
    private _router: Router,
    private broadcastService: MsalBroadcastService,
    private authServiceOLD: AuthService,
    private authService: MsalService) {
  }

  ngOnInit(): void {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })

    // msal
    this.currentUser = this.authServiceOLD.currentUserValue;
    if (!this.currentUser || this.currentUser.rol == Rol.CitizenOrApplicant) {
      this.broadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();

          if (this.authService.instance.getActiveAccount()) {
            let accessToken = this.authService.instance.getActiveAccount()?.homeAccountId + '-' + this.authService.instance.getActiveAccount()?.environment + '-idtoken-' + this.authService.instance.getActiveAccount()?.idTokenClaims.aud + '----'
            let refreshToken = this.authService.instance.getActiveAccount()?.homeAccountId + '-' + this.authService.instance.getActiveAccount()?.environment + '-refreshtoken-' + this.authService.instance.getActiveAccount()?.idTokenClaims.aud + '----'
            this.authServiceOLD.setCurrentUser(this.authService.instance.getActiveAccount()?.idTokenClaims, JSON.parse(localStorage.getItem(accessToken)).secret, JSON.parse(localStorage.getItem(refreshToken)).secret)
              .pipe(
                map((currentUser) => {
                  localStorage.setItem('currentUser', JSON.stringify(currentUser));
                  if (this.currentUser.rol == Rol.CitizenOrApplicant) {
                    const url = `${ RoutesApp.MainPage }/${ RoutesApp.MainPageCitizen }/${ RoutesApp.ActionsRequestOpa35 }`;
                    this._router.navigateByUrl(url);
                  }
                }),
                catchError((err) => {
                  return of(false);
                })
              ).subscribe(resp => {});
          }
        });
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
