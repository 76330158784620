import { Component, Inject, OnInit } from '@angular/core';
import { SSOAuthResponse } from "@models";
import { Rol, RoutesApp } from "@enums";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { AuthService, LoaderService, PopupService, ShareInfoService, UserService } from "@services";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter } from "rxjs/operators";
import { InteractionStatus, PublicClientApplication } from "@azure/msal-browser";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {

  local;
  opacity;
  currentUser!: SSOAuthResponse;
  routes = RoutesApp;
  amountOfNotification = 5;
  public isLogueado = false;
  public loadingRoles = false;
  public showRoles = false;
  rolesToDisplay: any[] = [];
  currentRol = Rol

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(private router: Router,
              private authServiceOLD: AuthService,
              private _shareInfoService: ShareInfoService,
              private _loaderService: LoaderService,
              private _userService: UserService,
              private popupService: PopupService,
              private _router: Router,
              private msalAuthService: MsalService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authServiceOLD.currentUserValue;
    this.authServiceOLD.currentUser.subscribe((res) => {
      if (res) {
        this.currentUser = res;
      }
    });

    if (this.currentUser) {
      if (this.currentUser.rol != Rol.CitizenOrApplicant) {
        this.getAssignedRoles(this.currentUser.email);
      }
    }
  }

  private getAssignedRoles(userEmail: string) {
    this.loadingRoles = true;
    this.rolesToDisplay = [];
    this._userService.getRolesByEmailUser(userEmail).subscribe({
      next: res => {
        this.loadingRoles = false;
        if (!res || res.length <= 1) {
          return;
        }
        for (const role of res) {
          let newRole = { id: null, key: null, name: null };
          switch (role) {
            case Rol.Logistic:
              newRole.id = 2;
              newRole.key = Rol.Logistic;
              newRole.name = 'Logístico';
              break;
            case Rol.Validator:
              newRole.id = 3;
              newRole.key = Rol.Validator;
              newRole.name = 'Validador';
              break;
            case Rol.Coordinator:
              newRole.id = 4;
              newRole.key = Rol.Coordinator;
              newRole.name = 'Coordinador';
              break;
            case Rol.Director:
              newRole.id = 5;
              newRole.key = Rol.Director;
              newRole.name = 'Director';
              break;
            case Rol.Revisor:
              newRole.id = 6;
              newRole.key = Rol.Revisor;
              newRole.name = 'Revisor';
              break;
          }
          this.rolesToDisplay.push(newRole);
        }
        this.showRoles = true;
        this.rolesToDisplay = this.rolesToDisplay.sort((a, b) => a.id - b.id);
      }, error: err => {
        this.loadingRoles = false;
        if (err?.status == 404) {
          return;
        }
      }
    });
  }

  setLogout = (): void => {
    if (this.msalAuthService.instance.getAllAccounts().length > 0) {
      this.msalAuthService.logoutPopup({
        mainWindowRedirectUri: "/"
      }).subscribe(resp => {
        this.popupService.infoAlert("¡Sesión cerrada exitosamente!", 4000);
        localStorage.clear();
        this.authServiceOLD.setCurrentUser(null);
      });
    } else {
      localStorage.clear();
      this.authServiceOLD.currentUserSubject.next(null);
      setTimeout(() => {
        this.router.navigateByUrl(RoutesApp.SignIn);
      }, 1000)
    }
  }

  setDefaultRole(role) {
    const userInformation = JSON.parse(String(localStorage.getItem('currentUser')));
    switch (role) {
      case 2:
        this._loaderService.showLoader();
        userInformation.rol = 'logistico';
        this.currentUser.rol = 'logistico';
        this.authServiceOLD.currentUserSubject.next(userInformation);
        localStorage.setItem('currentUser', JSON.stringify(userInformation));
        this.popupService.successAlert("Rol modificado correctamente, un momento por favor...", 3000);
        setTimeout(() => {
          window.location.href = `/#/${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.HomePageLogistic }`;
          this._loaderService.hideLoader();
        }, 3000);
        break;
      case 3:
        this._loaderService.showLoader();
        userInformation.rol = 'validador';
        this.currentUser.rol = 'validador';
        this.authServiceOLD.currentUserSubject.next(userInformation);
        localStorage.setItem('currentUser', JSON.stringify(userInformation));
        this.popupService.successAlert("Rol modificado correctamente, un momento por favor...", 3000);
        setTimeout(() => {
          window.location.href = `/#/${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardValidator }`;
          this._loaderService.hideLoader();
        }, 3000);
        break;
      case 4:
        this._loaderService.showLoader();
        userInformation.rol = 'coordinador';
        this.currentUser.rol = 'coordinador';
        this.authServiceOLD.currentUserSubject.next(userInformation);
        localStorage.setItem('currentUser', JSON.stringify(userInformation));
        this.popupService.successAlert("Rol modificado correctamente, un momento por favor...", 3000);
        setTimeout(() => {
          window.location.href = `/#/${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardCoordinator }`;
          this._loaderService.hideLoader();
        }, 3000);
        break;
      case 5:
        this._loaderService.showLoader();
        userInformation.rol = 'director';
        this.currentUser.rol = 'director';
        this.authServiceOLD.currentUserSubject.next(userInformation);
        localStorage.setItem('currentUser', JSON.stringify(userInformation));
        this.popupService.successAlert("Rol modificado correctamente, un momento por favor...", 3000);
        setTimeout(() => {
          window.location.href = `/#/${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardDirector }`;
          this._loaderService.hideLoader();
        }, 3000);
        break;
      case 6:
        this._loaderService.showLoader();
        userInformation.rol = 'revisor';
        this.currentUser.rol = 'revisor';
        this.authServiceOLD.currentUserSubject.next(userInformation);
        localStorage.setItem('currentUser', JSON.stringify(userInformation));
        this.popupService.successAlert("Rol modificado correctamente, un momento por favor...", 3000);
        setTimeout(() => {
          window.location.href = `/#/${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardRevisor }`;
          this._loaderService.hideLoader();
        }, 3000);
        break;
      default:
        this._loaderService.showLoader();
        this.authServiceOLD.currentUserSubject.next(userInformation);
        setTimeout(() => this._loaderService.hideLoader(), 3000);
        break;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
