<div class="justify-content-center">
  <div class="bg-white text-center" style="height: 70vh;">
    <div class="container" style="color: #004884!important;">
      <h1 class="display-1 font-weight-bold">404</h1>
      <h1 class="display-4 pt-1 pb-3">Página no encontrada</h1>
      <h3 class="font-weight-light text-secondary">La página que está buscando podría <br> haber sido eliminada</h3>
      <button class="btn btn-round btn-high mt-3 pt-3 pb-3 pr-4 pl-4" (click)="returnHome()">Regresar</button>
    </div>
  </div>
</div>
