import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { OPA_35 } from "@consts";

const { TRAMITE_SECURITY_URL } = environment;

/**
 * Service para la API de SECURITY del API MANAGER de la SDS
 */
@Injectable({
  providedIn: 'root'
})
export class TramiteSecurityService {

  constructor(private http: HttpClient) {
  }

  /**
   * Obtiene el codigo de ventanilla de una persona dado el idToken o sub
   * @param idToken IdToken o sub
   */
  public getCodeVentanillaByIdUser(idToken): Observable<any> {
    return this.http.get(`${ TRAMITE_SECURITY_URL }/GetCodeVentanillaByIdUser/${ idToken }`);
  }

  /**
   * Obtiene los usuarios por rol y tramite
   * @param roleGuId ID del rol en tramites
   */
  public getUsersByRole(roleGuId: string): Observable<any> {
    return this.http.get(`${ TRAMITE_SECURITY_URL }/GetUserbyIdrol/${ roleGuId }/${ OPA_35.T_ID }`);
  }

  /**
   * Obtiene el rol de un usuario dado su oid del b2c
   * @param oid Id dado por el b2c del usuario a buscar
   */
  getRoleByIdUser(oid: string): Observable<any> {
    return this.http.get(`${ TRAMITE_SECURITY_URL }/GetRoleByIdUser/${ oid }`);
  }
}
