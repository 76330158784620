import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./core/core.module";
import { QuillModule } from "ngx-quill";

// antd configure
import es from '@angular/common/locales/es';
import { en_US, es_ES, NZ_I18N } from "ng-zorro-antd/i18n";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalModule,
  MsalService
} from "@azure/msal-angular";


import { InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { DemoNgZorroAntdModule } from "./ng-antd.module";
import { MaterialModule } from "./material.module";
import { msalConfig } from "./auth-config";
import { MsalLocalGuard } from "@guards";
import { JwtInterceptor } from "./core/interceptors/jwt.interceptor";
import { NgxLoadingModule } from "ngx-loading";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";


registerLocaleData(es);
// const antDesignIcons = AllIcons as {
//   [key: string]: IconDefinition;
// };
// const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup, // InteractionType.Redirect
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,

    AppRoutingModule,
    CoreModule,
    DemoNgZorroAntdModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          [ 'bold', 'italic', 'underline', 'strike' ],
          [ { font: [] } ],
          [ { color: [] }, { background: [] } ],
          [ { size: [ 'small', false, 'large', 'huge' ] } ],
          [ { header: [ 1, 2, 3, 4, 5, 6, false ] } ],
          [ { align: [] } ],
          [ 'blockquote' ],
          [ { list: 'ordered' }, { list: 'bullet' } ],
          [],
          [ 'clean' ],
        ]
      }
    }),
    MaterialModule,
    MsalModule,
    NgxExtendedPdfViewerModule,
    NgxLoadingModule.forRoot({})
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NZ_I18N, useValue: es_ES },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: Window, useValue: window },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalLocalGuard,
    MsalBroadcastService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
}
