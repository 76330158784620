import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalLocalGuard, IsLoggedGuard, HasAssignedRoleGuard } from "@guards";
import { RoutesApp } from "@enums";
import { SigninComponent } from "./core/components/signin/signin.component";
import { NotFoundComponent } from "./core/components/not-found/not-found.component";

const routes: Routes = [
  {
    path: RoutesApp.SignIn,
    component: SigninComponent,
    canActivate: [ IsLoggedGuard ]
  },
  {
    path: RoutesApp.MainPage,
    canActivate: [ MsalLocalGuard, HasAssignedRoleGuard ],
    loadChildren: () => import('./feature/feature.module').then(m => m.FeatureModule)
  },
  { path: '', redirectTo: RoutesApp.MainPage, pathMatch: 'full' },
  { path: 'state', redirectTo: RoutesApp.MainPage, pathMatch: 'full' }, // only for b2c response
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
