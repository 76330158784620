import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SSOAuthResponse } from '@models';
import { AuthService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class RedirectToSdsBaseUrlGuard implements CanActivate {

  loggedUser: SSOAuthResponse;

  constructor(private authServiceOld: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    this.loggedUser = this.authServiceOld.currentUserValue;
    return this.checkUser(route, url);
  }

  checkUser(route: ActivatedRouteSnapshot, url: any): boolean {
    if (url == '/opa35/inbox') {
      console.log('Hola!');
      debugger;
      this.authServiceOld.redirectByRole(this.loggedUser.rol);
      return false;
    }
    return true;
  }

}
