import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Rol, RoutesApp } from "@enums";
import { AuthService } from "@services";
import { SSOAuthResponse } from "@models";

@Injectable({
  providedIn: 'root'
})
export class IsSdsOfficialGuard implements CanActivate {
  currentUser: SSOAuthResponse;

  constructor(private authServiceOld: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkOfficialUser(route, url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }


  checkOfficialUser(route: ActivatedRouteSnapshot, url: any): boolean {
    this.currentUser = this.authServiceOld.currentUserValue;
    if (this.currentUser) {
      if (this.currentUser.rol == Rol.CitizenOrApplicant) {
        this.router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.MainPageCitizen }/${ RoutesApp.ActionsRequestOpa35 }`);
        return false;
      }
      return true;
    }

    this.authServiceOld.cleanAll();
    this.router.navigateByUrl(`/${ RoutesApp.SignIn }`);
    return false;
  }

}
