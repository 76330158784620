import { getColombiaHolidaysByYear } from 'colombia-holidays';
import moment from "moment-timezone";

export function letGoTop() {
  let mainInter = document.getElementById('interno');
  mainInter?.scroll(0, 0);
  let main = document.getElementById('content-layout-id');
  main?.scroll(0, 0);
}

export function isValidDate(dateString) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false;  // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
}

/**
 * Realiza la conversión de un base64 a blob
 * @param b64Data
 * @param contentType
 * @param sliceSize
 */
export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export const blobToBase64 = async blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.toString().split(',').pop())
    // @ts-ignore
    //reader.error = (err) => reject(err)
    reader.readAsDataURL(blob)
  })
}


export function convertBase64ToPdfFile(base64: string, filename: string): File {
  try {
    base64 = `data:application/pdf;base64,${ base64 }`;
    const base64Arr = base64.split(',');
    const mime = base64Arr[0].match(/:(.*?);/)[1];
    const bstr = atob(base64Arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([ u8arr ], filename, { type: mime });
  } catch (e) {
    return null;
  }
}

export function blobToFile(theBlob, fileName){
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

/**
 * Obtiene los dias festivos del(los) anios indicados
 * @param years array de anios
 */
export function getHolidaysByYears(years: any[]) {
  const holidays = [];
  for (const _year of years) {
    const holidaysInYear = getColombiaHolidaysByYear(_year);
    for (const holidayElement of holidaysInYear) {
      holidays.push(holidayElement.holiday);
    }
  }
  return holidays;
}

/**
 * Agrega dias laborales a una fecha
 * @param dateToCheck fecha indicada
 * @param daysToAdd dias laborales a sumar
 * @param holidays array de fechas festivas (no requerido) [2023-09-09,2023-09-10]
 */
export function addWorkingDaysToDate(dateToCheck: Date, daysToAdd: number, holidays = []) {
  const result = {
    daysAdded: daysToAdd,
    nonWorkingDays: 0,
    businessDaysRemainingUntilToday: daysToAdd,
    businessDaysDiff: 0,
    startDate: new Intl.DateTimeFormat("es-CO", {
      dateStyle: "medium",
      timeStyle: "medium",
      timeZone: 'America/Bogota'
    }).format(dateToCheck),
    lastDate: null
  };
  const currentDate = moment().tz('America/Bogota').format('YYYY-MM-DD');
  const dateToVerify = dateToCheck
  for (let i = 1; i <= daysToAdd; i++) {
    let formatDate = dateToVerify.toISOString().split('T')[0]
    if (formatDate < currentDate) {
      result.businessDaysRemainingUntilToday--;
    }
    dateToVerify.setDate(dateToVerify.getDate() + 1);
    if (dateToVerify.getDay() === 6) {
      dateToVerify.setDate(dateToVerify.getDate() + 2);
      result.nonWorkingDays = result.nonWorkingDays + 2;
    } else if (dateToVerify.getDay() === 0 || holidays.indexOf(formatDate) != -1) {
      dateToVerify.setDate(dateToVerify.getDate() + 1);
      result.nonWorkingDays++;
    }
  }
  result.businessDaysDiff = daysToAdd - result.businessDaysRemainingUntilToday;
  result.lastDate = new Intl.DateTimeFormat("es-CO", {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: 'America/Bogota'
  }).format(dateToVerify);
  return result;
}

