import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService, ShareInfoService } from "@services";
import { Subscription } from "rxjs";
import { ngxLoadingAnimationTypes } from "ngx-loading";

const PrimaryWhite = '#ffffff';
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: [ './loading.component.scss' ]
})
export class LoadingComponent implements OnInit, OnDestroy {
  public loading: boolean = false;
  public loadingSubscription: Subscription;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = PrimaryWhite;

  constructor(public _loaderService: LoaderService) {
  }

  ngOnInit() {
    this.loadingSubscription = this._loaderService.getLoading$
      .subscribe(resp => {
        this.loading = resp;
      });
  }

  ngOnDestroy() {
    if (this.loadingSubscription)
      this.loadingSubscription.unsubscribe();
  }

}
