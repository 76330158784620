<nav class="navbar fixed-top navbar-expand-lg navbar-gov-co">
  <div class="navbar-gov-co-pri container-fluid">
    <div class="navbar-logo float-left">
      <a class="navbar-brand" href="https://www.gov.co/" target="_blank" rel="noopener">
        <img src="https://cdn.www.gov.co/assets/images/logo.png" height="30" alt="Logo Gov.co" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapsible"
        aria-controls="navbarCollapsible"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse navbar-first-menu float-right">
      <div class="nav-primary mx-auto">
        <ul class="navbar-nav ml-auto nav-items nav-items-desktop"></ul>
      </div>
    </div>

    <!-- user-->
    <div class="nav-item-primary ml-auto mr-2 is-scroll" *ngIf="currentUser">
      <div class="d-flex" style="color: white; font-weight: bold; padding: 5px">

        <button mat-button [matMenuTriggerFor]="aboveMenu" *ngIf="showRoles" style="background: #FFFFFF; margin-right: 20px; text-transform: capitalize">
          <mat-icon>settings</mat-icon> {{ currentUser.rol  || 'No Disponible' }}
        </button>

        <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <img class="default-img-profile"
               src="./assets/icons/user.png"
                alt="name-citizen">
          <span class="profile-user-name"> {{ currentUser?.name || 'No Disponible' }}</span>
        </button>
        <mat-menu class="user-menu" #menu="matMenu" xPosition="before">
          <button mat-menu-item (click)="setLogout()">
            <span>Cerrar sesión</span>
          </button>
        </mat-menu>

        <mat-menu class="user-menu" #aboveMenu="matMenu" yPosition="above">
          <button mat-menu-item *ngFor="let role of rolesToDisplay"
                  [style.display]="role.key == currentUser.rol ? 'none' : 'block'"
                  (click)="setDefaultRole(role.id)">
            {{ role.name }}
          </button>
        </mat-menu>

      </div>
    </div>
    <!-- user-->

  </div>
</nav>

<div class="navbar-gov-co-search">
  <div class="content-logo" style="background-color: #FFFFFF!important;">
    <a href="http://www.saludcapital.gov.co/" target="_blank" rel="noopener">
      <img src="./assets/images/logoSDS.png" alt="Secretaria Distrital de Salud">
    </a>
  </div>
</div>
