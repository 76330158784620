import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SSOAuthResponse } from "@models";
import { AuthService } from "@services";
import { Rol, RoutesApp } from "@enums";

@Injectable({
  providedIn: 'root'
})
export class IsRevisorGuard implements CanActivate {
  currentUser: SSOAuthResponse;

  constructor(private authServiceOld: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.verifyRevisorRole(route, url);
  }

  verifyRevisorRole(route: ActivatedRouteSnapshot, url: any): boolean {
    this.currentUser = this.authServiceOld.currentUserValue;
    if (this.currentUser) {
      if (this.currentUser.rol != Rol.Revisor) {
        this.router.navigateByUrl(`not-found`);
        return false;
      }
      return true;
    }

    this.authServiceOld.cleanAll();
    this.router.navigateByUrl(`/${ RoutesApp.SignIn }`);
    return false;
  }

}
