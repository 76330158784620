import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  totalDocuments = new BehaviorSubject(null);
  totalDocuments$ = this.totalDocuments.asObservable();

  documentsSolicSubject = new BehaviorSubject(null);
  public readonly documentsSolicitanteTarget$ = this.documentsSolicSubject.asObservable();

  documentsRequired = new BehaviorSubject(null);
  public readonly documentsRequired$ = this.documentsRequired.asObservable();

  setShareRequirementDocs = new BehaviorSubject(null);
  getShareRequirementDocs$ = this.setShareRequirementDocs.asObservable();

  setFileDeleted = new BehaviorSubject<string>(null);
  getFileDeleted$ = this.setFileDeleted.asObservable();

  constructor(private http: HttpClient) {
  }

  setDocsAdded(document: any) {
    this.documentsSolicSubject.next(document);
  }

  setDocsRequire(requiredDoc: any) {
    this.documentsRequired.next(requiredDoc);
  }

  public get docsAdded() {
    return this.documentsSolicSubject.value || [];
  }

  public numDocsRequired(requirements) {
    const docsRequired = [];
    for (const docsEl of requirements) {
      if (docsEl.required) {
        docsRequired.push(docsEl.id);
      }
    }
    return docsRequired;
  }

  public docsRequiredOk(requirements): boolean {
    const docsRequired = this.numDocsRequired(requirements);
    let docsAddedRequired = 0;
    for (const docReqEl of this.docsAdded) {
      if (docsRequired.indexOf(docReqEl.idReqdocument) != -1) {
        docsAddedRequired++;
      }
    }
    return docsAddedRequired == docsRequired.length;
  }


  /**
   * Guarda los archivos en blobstorage
   * @param formData
   */
  saveFilesInBlobStorage(formData: any): Observable<any> {
    return this.http.post(`${ environment.TRAMITE_FILE_MANAGER_URL }/Storage/AddFile`, formData);
  }

  /**
   * Obtiene el archivo guardado en blobstorage
   * @param numRadicado
   * @param fileName
   */
  getFileSavedInBlobStorage(numRadicado: string, fileName: string) {
    const options = { responseType: 'blob' as 'json' }
    return this.http.get(`${ environment.TRAMITE_FILE_MANAGER_URL }/Storage/GetBlob/${ environment.CONTAINER_NAME }/${ numRadicado }/${ fileName }`, options);
  }

  /**
   * Obtiene los documentos a cargar para cada tipo de tramite
   * @param typeId
   */
  getFilesRequiredByProcedureTypeId(typeId: string): Observable<any[]> {
    return this.http.get<any[]>(`${ environment.OPA35_REQUEST_URI }/TiposDocumentos/ByTipoTramite/${ typeId }`);
  }

  /**
   * Elimina un documento de una solicitud
   * @param idDocumento
   */
  deleteDocument(idDocumento: string): Observable<any> {
    return this.http.delete(`${ environment.OPA35_REQUEST_URI }/Documentos/${ idDocumento }`);
  }
}
