import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable, of, Subscription, switchMap } from 'rxjs';
import { AuthModel, Authentication, SSOAuthResponse } from '@models';
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { Rol, RoutesApp } from "@enums";
import { TramiteSecurityService } from "./tramite-security.service";
import { TramiteSharedService } from "./tramite-shared.service";
import { MsalService } from "@azure/msal-angular";
import { PopupService } from "./popup.service";

@Injectable({ providedIn: 'root' })
export class AuthService {
  urlInter = 'https://login-appservice-back2.azurewebsites.net/auth/';
  public currentUserSubject: BehaviorSubject<SSOAuthResponse>;
  public currentUser: Observable<SSOAuthResponse>;
  private users!: AuthModel[];
  private userByDefault!: SSOAuthResponse;
  counterChangesRefresh = 1;
  response = false;

  subSolicitante = new BehaviorSubject(null);
  subSolicitante$ = this.subSolicitante.asObservable();

  private noInterceptorHttpClient: HttpClient;

  constructor(private http: HttpClient, handler: HttpBackend,
              private router: Router,
              private msalService: MsalService,
              private popupService: PopupService,
              private tramiteSecurityService: TramiteSecurityService) {
    this.users = [];

    this.currentUserSubject = new BehaviorSubject<SSOAuthResponse>(JSON.parse(String(localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();

    this.noInterceptorHttpClient = new HttpClient(handler);
  }

  public get currentUserValue(): SSOAuthResponse {
    return this.currentUserSubject.value;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  isCurrentUserAndNotTokenExpired(): boolean {
    try {
      const currentUser = this.currentUserValue;
      const expiry = JSON.parse(atob(currentUser.access_token.split('.')[1])).exp;
      const localTime = Math.floor((new Date).getTime() / 1000);
      if (localStorage.getItem('dateIn') === undefined || localStorage.getItem('dateIn') === null) {
        localStorage.setItem('dateIn', new Date().toString());
      }
      const before = new Date(localStorage.getItem('dateIn'));
      let dataRefresh = 0;
      if (!isNaN(currentUser.refresh_expires_in)) {
        dataRefresh = currentUser.refresh_expires_in;
      }
      let refresh = (0.9 * dataRefresh) / 60;
      const timeReal = new Date();
      const diff = Math.round(((timeReal.getTime() - before.getTime()) / 60000) / this.counterChangesRefresh);
      const valueExpire = !!localStorage.getItem('currentUser') ? !!JSON.parse(localStorage.getItem('currentUser')).expires_in ? JSON.parse(localStorage.getItem('currentUser')).expires_in : 0 : 0;
      const compareRefresh = 0.9 * (valueExpire / 60);
      if (refresh <= diff) {
        this.cleanAll();
        return false;
      } else if (compareRefresh <= diff) {
        return this.response;
      } else {
        //return currentUser && localTime < expiry ? true : false;
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  setCurrentUser(claims: any, idToken?: any, refreshToken?: any): Observable<SSOAuthResponse> {
    const currentUser: SSOAuthResponse = {
      access_token: idToken ? idToken : "token",
      refresh_token: refreshToken ? refreshToken : "refresh_token",
      refresh_expires_in: claims['exp'] || 10000,
      notBeforePolicy: 100000,
      id_token: "id_token",
      session_state: "session_state",
      expires_in: claims['exp'],
      rol: "solicitante",
      sid: "sid",
      email_verified: true,
      name: claims['name'],
      preferred_username: claims['emails'][0],
      given_name: claims['given_name'],
      family_name: claims['family_name'],
      email: claims['emails'],
      secondName: claims['extension_SegundoNombre'],
      secondSurname: claims['extension_SegundoApellido'],
      phone: claims['extension_TelefonoCelular'],
      extension_TipodeIdentificacion: claims['extension_TipodeIdentificacion'],
      documentNumber: claims['extension_NumerodeIdentificacion'],
      extension_Juridico: claims['extension_Juridico'],
      aud: claims['aud'],
      auth_time: claims['auth_time'],
      // city: claims['city'],
      emails: claims['emails'],
      // jobTitle: claims['jobTitle'],
      sub: claims['sub']
    };

    this.currentUserSubject.next(currentUser);
    return of(currentUser);
  }

  public loginB2CAzure(): any {
    this.msalService.loginPopup().subscribe(
      res => {
        this.tramiteSecurityService.getCodeVentanillaByIdUser(res.account.idTokenClaims.oid).subscribe({
          next: (ventanillaCode) => {
            if (ventanillaCode.data == -1) {
              const vtUrl = environment.VUDTS_URL;
              this.popupService.errorAlert(`Hemos detectado que no se ha completado el registro, porfavor dirigase al portal de <a href='${vtUrl}' target="_blank" rel="noopener">Agilinea</a>`, 5000);
              setTimeout(() => this.cleanAllAndLogout(), 4000);
              return;
            }
            this.tramiteSecurityService.getRoleByIdUser(res.account.idTokenClaims.oid).subscribe(role => {
              if (role.data != null) {
                const currentUser = {
                  access_token: res.idToken,
                  sub: res.account.idTokenClaims.sub,
                  refresh_token: res.account.idTokenClaims.auth_time + "",
                  refresh_expires_in: res.account.idTokenClaims.exp,
                  notBeforePolicy: res.account.idTokenClaims.iat ? res.account.idTokenClaims.iat : 0,
                  scope: res.scopes[0] ? res.scopes[0] : '',
                  id_token: res.idToken ? res.idToken : '',
                  token_type: res.tokenType,
                  session_state: res.state ? res.state : '',
                  expires_in: res.account.idTokenClaims.exp,
                  rol: this.getCurrentRole(role.data[0].value.toLowerCase()),
                  sid: '',
                  email_verified: role.data[0].email ? role.data[0].email : false,
                  name: res.account.idTokenClaims.name,
                  preferred_username: res.account.username,
                  given_name: "",
                  family_name: "",
                  email: role.data[0].email,
                  userId: res.account.idTokenClaims.oid,
                };
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
                this.currentUserSubject.next(currentUser);
                this.redirectByRole(currentUser.rol);
              } else {
                this.popupService.errorAlert('Usuario no registrado por el flujo', 4000);
                setTimeout(() => this.cleanAllAndLogout(), 4000)
              }
            });
          },
          error: (err) => {
            this.popupService.errorAlert('Usuario no registrado por el flujo', 4000);
            setTimeout(() => this.cleanAllAndLogout(), 4000)
          }
        });
      }
    )
  }

  public loginVentanilla() {
    const ventanilla = JSON.parse(localStorage.getItem('datosventanilla'));
    if (ventanilla) {
      this.tramiteSecurityService.getRoleByIdUser(ventanilla.oid).subscribe(role => {
        if (role.data != null) {
          const currentUser = {
            access_token: ventanilla.flujo.idToken,
            sub: ventanilla.flujo.account.idTokenClaims.sub,
            refresh_token: ventanilla.flujo.account.idTokenClaims.auth_time + "",
            refresh_expires_in: ventanilla.flujo.account.idTokenClaims.exp,
            notBeforePolicy: ventanilla.flujo.account.idTokenClaims.iat ? ventanilla.flujo.account.idTokenClaims.iat : 0,
            scope: ventanilla.flujo.scopes[0] ? ventanilla.flujo.scopes[0] : '',
            id_token: ventanilla.flujo.idToken ? ventanilla.flujo.idToken : '',
            token_type: ventanilla.flujo.tokenType,
            session_state: ventanilla.flujo.state ? ventanilla.flujo.state : '',
            expires_in: ventanilla.flujo.account.idTokenClaims.exp,
            rol: this.getCurrentRole(role.data[0].value.toLowerCase()),
            sid: '',
            email_verified: role.data[0].email ? role.data[0].email : false,
            name: ventanilla.flujo.account.idTokenClaims.name,
            preferred_username: ventanilla.flujo.account.username,
            given_name: ventanilla.flujo.account.idTokenClaims.given_name + "",
            family_name: ventanilla.flujo.account.idTokenClaims.family_name + "",
            email: role.data[0].email,
            userId: ventanilla.flujo.account.idTokenClaims.sub,
          };
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.currentUserSubject.next(currentUser);
          this.redirectByRole(currentUser.rol);
        } else {
          this.popupService.errorAlert('Usuario no registrado por el flujo', 4000);
          this.cleanAll();
        }
      });
    }
  }


  private getCurrentRole(role: string): string {
    if (role == 'ciudadano') {
      return 'solicitante';
    } else if (role == 'funcionario') {
      return 'validador';
    }
    return role;
  }

  redirectByRole(role: string) {
    switch (role) {
      case Rol.CitizenOrApplicant:
        this.router.navigate([ RoutesApp.MainPage, RoutesApp.MainPageCitizen ]);
        break;
      case Rol.Logistic:
        this.router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.HomePageLogistic }`);
        break;
      case Rol.Validator:
        this.router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardValidator }`);
        break;
      case Rol.Coordinator:
        this.router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardCoordinator }`);
        break;
      case Rol.Director:
        this.router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }/${ RoutesApp.DashboardDirector }`);
        break;
      default:
        this.popupService.errorAlert("El rol con el que cuenta el usuario no esta habilitado para este trámite", 3000);
        setTimeout(() => this.cleanAllAndLogout(), 3000);
        //this.router.navigateByUrl(`${ RoutesApp.MainPage }/${ RoutesApp.Dashboard }`);
        return;
    }
    this.popupService.infoAlert('Bienvenid@ a Secretaría de Salud Bogotá.', 4000);
  }

  cleanAll(redirectToSignIn: boolean = true) {
    localStorage.clear();
    this.currentUserSubject.next(null);
    if (redirectToSignIn) {
      this.router.navigateByUrl(RoutesApp.SignIn);
    }
  }

  cleanAllAndLogout() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/"
      }).subscribe(resp => {
        //this.popupService.infoAlert("¡Sesión cerrada exitosamente!", 4000);
        localStorage.clear();
        this.currentUserSubject.next(null);
      });
    } else {
      localStorage.clear();
      this.currentUserSubject.next(null);
      this.router.navigateByUrl(RoutesApp.SignIn);
    }
  }
}
