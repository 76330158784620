import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { concatMap, map, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class TramiteSharedService {

  constructor(private http: HttpClient) {
  }

  getPaises() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Pais/GetPais`).pipe(map(resp => {
      return resp.data.map(pais => {
        return {
          name: pais.nombre,
          value: pais.idPais
        }
      });
    }));
  }

  getDepartments() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Departamento/GetDepartamento`).pipe(map(resp => {
      return resp.data.map(departamento => {
        return {
          name: departamento.descripcion,
          value: departamento.idDepartamento
        }
      });
    }));
  }

  getCities(idDepartment: string) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Municipio/GetMunicipioByIdDepartamento/${ idDepartment }`).pipe(map(resp => {
      return resp.data.map(city => {
        return {
          name: city.descripcion,
          value: city.idMunicipio
        }
      });
    }));
  }

  getZones() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/SubRed/GetSubRed`);
  }

  getLocalities(): Observable<any> {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Localidad/GetAllLocalidad`);
  }

  getLocalitiesByZoneId(zoneId: number): Observable<any> {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Localidad/GetAllLocalidad`)
      .pipe(map(resp => resp?.data.filter(local => local.id_subred == zoneId)));
  }

  getUpz() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Upz/GetUpz`);
  }

  getUpzByLocalityId(localityId: number) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Upz/GetUpzByIdLocalidad/${ localityId }`);
  }

  getBarrios() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrios`);
  } //idNeigh

  getBarrioById(idBarrio) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrios`).pipe(
      map(resp => resp?.data.filter(barrio => barrio.id_barrio == idBarrio))
    );
  }

  getBarriosByUpz(idUpz) {
    //@todo -> el servicio que hay en sharedApi falla ${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrioByIdUpz/${ idUpz }
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrios`).pipe(
      map(resp => resp?.data.filter(barrio => barrio.id_upz == idUpz))
    );
  }

  /**
   * Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
  getInfoUserByIdCodeVentanilla(idCode: any): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v2/Persona/GetInfoUserById/${ idCode }`);
  }

  /**
   * Obtiene lista de Tipos de Identificacion
   */
  getIdentificationType(): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v1/TipoIdentificacion/GetTipoIdentificacion`);
  }

  /**
   * Obtiene la lista de niveles educativos
   */
  getEducationsLevel(): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v1/NivelEducativo/GetNivelEducativo`);
  }

  /**
   * Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
  getCodeVentanillaByIdUser(idCode: any): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SECURITY_URL }/GetCodeVentanillaByIdUser/${ idCode }`);
  }

  /**
   * Obtiene la información de la persona en VT
   * @param docNumber
   */
  getPersonInformationByDocNumberVU(docNumber: number): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v2/Persona/GetInfoUserByIdDocument/${ docNumber }`);
  }

  /**
   * Guarda Datos Persona Natural
   */
  public updatePerson(data: any): Observable<any> {
    return this.http.put(`${ environment.TRAMITE_SHARED_URL }/v2/Persona/UpdatePerson `, data);
  }

  /**
   * Georeferenciador
   * @param address
   */
  getAddress(address: string): Observable<any> {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Direccion/GetDireccion/${ address }`);
  }

}
