import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { SSOAuthResponse } from "@models";
import { AuthService } from "@services";
import { Rol, RoutesApp } from "@enums";

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate {
  currentUser: SSOAuthResponse;

  constructor(private authServiceOld: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkIsLogged(route, url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }


  checkIsLogged(route: ActivatedRouteSnapshot, url: any): boolean {
    this.currentUser = this.authServiceOld.currentUserValue;
    if (this.currentUser) {
      this.router.navigateByUrl(`/${ RoutesApp.MainPage }`);
      return false;
    }
    return true;
  }


}
