export enum StatusRequest {
  POR_ASIGNAR = '5B4E1902-D66B-4348-87E1-4C352B7A6420',
  POR_VALIDAR = '3F74A183-0DE0-4D8D-8F7A-39B1F42AD563',
  APROBADO_VALIDADOR = '8877A285-0C57-49C2-9B57-23441C2F238C',
  SOLICITAR_INFO = '0D7F95A7-25D1-4C62-98C5-85D837E6DD46',
  SUBSANADO = 'AE4C301C-39BC-44F0-A524-03D4116DDE7C',
  SIN_COMPETENCIA_NO_RESIDE = '98C1E2AF-9360-4158-9268-38A778C195E6',
  APROBADO_COORDINADOR = '9569968E-B873-4183-A3DB-4F862D18D592',
  DEVUELTO_COORDINADOR = '5838366F-4DFB-41AF-A324-95CC7A954EAA',
  APROBADO_REVISOR = '8BD73EC2-99AE-400D-A73B-3FEF8FEAFEAE',
  DEVUELTO_REVISOR = '39D5A153-B551-4EF5-A8EF-E10AA8784CF6',
  APROBADO_Y_FIRMADO = '7328A1EA-465C-41DB-A7BE-961086CA7D43',
  DEVUELTO_DIRECTOR = '04D09336-1DBD-4F0B-BED5-8DF5618FBAC4',

}

/*
Devuelto Revisor -> 39D5A153-B551-4EF5-A8EF-E10AA8784CF6
Aprobado Validador -> 8877A285-0C57-49C2-9B57-23441C2F238C
Subsanado -> AE4C301C-39BC-44F0-A524-03D4116DDE7C
Por Asignar -> 5B4E1902-D66B-4348-87E1-4C352B7A6420
Aprobado Coordinador -> 9569968E-B873-4183-A3DB-4F862D18D592
Sin Competencia (Régimen especial/no reside en Bogotá) -> 98C1E2AF-9360-4158-9268-38A778C195E6
Por Validar -> 3F74A183-0DE0-4D8D-8F7A-39B1F42AD563
Aprobado Revisor -> 8BD73EC2-99AE-400D-A73B-3FEF8FEAFEAE
Devuelto Coordinador -> 5838366F-4DFB-41AF-A324-95CC7A954EAA
Aprobado y Firmado -> 7328A1EA-465C-41DB-A7BE-961086CA7D43
Solicitar Más Información -> 0D7F95A7-25D1-4C62-98C5-85D837E6DD46
Devuelto Director -> 04D09336-1DBD-4F0B-BED5-8DF5618FBAC4
 */


export enum ModificationTypePersonalInformation {
  BASIC_DATA= 1,
  CONTACT_DATA = 2,
  PLACE_DATA = 3,
  ALL_DATA = 4
}

export enum ProcedureTypes {
  FIRST_TIME= '2E8697C9-9E1A-4B92-A51E-8FFA9D2383ED',
  SECOND_OPINION = '67F18E0A-B3B3-408F-BAE0-56D1E7E92DED',
  DF_UPDATE_INFO = 'default',
  CONDITION_HEALTH_CHANGE = 'DF760644-678F-4FBA-B923-9C874861AB87',
  SIX_YEARS = 'B37DB60F-5835-47F6-9394-DC0CA8B20645',
  SEVEN_YEARS = '717033EF-8A27-445E-9623-8CB597290287',
  EIGHTEEN_YEARS = '9A7AB024-A7DF-4E68-99FA-CFA47D4A797A',
  LINK_SURVEY = 'F4EFA118-2C4C-4334-BA5E-667E227E80C9',
  PERSONAL_INFORMATION = '741A8538-5F54-49F9-BD99-0BEDB4CDE45A'
}

export enum ProcedureTypeNames {
  '2e8697c9-9e1a-4b92-a51e-8ffa9d2383ed' = 'Primera Vez',
  '67f18e0a-b3b3-408f-bae0-56d1e7e92ded' = 'Segunda Opinión',
  'df760644-678f-4fba-b923-9c874861ab87' = 'Cambio en condición de salud',
  'b37db60f-5835-47f6-9394-dc0ca8b20645' = '6 años (nueva valoración)',
  '717033ef-8a27-445e-9623-8cb597290287' = '7 años',
  '9a7ab024-a7df-4e68-99fa-cfa47d4a797a' = '18 años (nueva valoración)',
  'f4efa118-2c4c-4334-ba5e-667e227e80c9' = 'Encuesta autoreconocimiento',
  '741a8538-5f54-49f9-bd99-0bedb4cde45a' = 'Información personal y/o residencia'
}
