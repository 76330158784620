<div class="loading-container">
  <ngx-loading
    [show]="(_loaderService.getLoading$ | async)"
    [config]="{
      backdropBorderRadius: '3px',
      fullScreenBackdrop: true,
      animationType: ngxLoadingAnimationTypes.doubleBounce,
      primaryColour
    }"
  ></ngx-loading>
</div>
