import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private setLoading = new BehaviorSubject<boolean>(false);
  getLoading$ = this.setLoading.asObservable();

  constructor() { }

  showLoader() {
    this.setLoading.next(true);
  }

  hideLoader() {
    this.setLoading.next(false);
  }
}
