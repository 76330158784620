import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getRolesByEmailUser(email: string): Observable<any> {
    return this.http.get(`${ environment.OPA35_REQUEST_URI }/Usuarios/RolesPorUsuario/${ email.toLowerCase() }`);
  }
}
